import { __assign, __rest } from "tslib";
import m from 'mithril';
import classnames from 'classnames';
import { Classes, Breakpoints } from '../../_shared';
var Col = /** @class */ (function () {
    function Col() {
    }
    Col.prototype.view = function (_a) {
        var attrs = _a.attrs, children = _a.children;
        var span = attrs.span, order = attrs.order, offset = attrs.offset, className = attrs.class, htmlAttrs = __rest(attrs, ["span", "order", "offset", "class"]);
        var breakpointClasses = '';
        Object.keys(Breakpoints).map(function (breakpoint) {
            breakpointClasses = classnames(breakpointClasses, typeof span === 'object' && span[breakpoint] && Classes.COL + "-" + breakpoint + "-" + span[breakpoint], typeof order === 'object' && order[breakpoint] && Classes.COL + "-" + breakpoint + "-order-" + order[breakpoint], typeof offset === 'object' && offset[breakpoint] && Classes.COL + "-" + breakpoint + "-offset-" + offset[breakpoint]);
        });
        var classes = classnames(breakpointClasses, typeof span === 'number' && Classes.COL + "-" + span, typeof order === 'number' && Classes.COL + "-order-" + order, typeof offset === 'number' && Classes.COL + "-offset-" + offset, className);
        return m('', __assign(__assign({}, htmlAttrs), { class: classes }), children);
    };
    return Col;
}());
export { Col };
